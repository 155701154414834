import React from 'react'
import { Select as AntdSelect } from 'antd'

const { Option } = AntdSelect

const Select = ({
  options,
  selected,
  placeholder,
  onChange,
  disabled,
  value,
}) => {
  return (
    <AntdSelect
      disabled={disabled}
      showSearch
      style={{ width: 300 }}
      placeholder={placeholder}
      onChange={onChange}
      filterOption={(input, option) => {
        return (
          option &&
          option.label &&
          option.label
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .indexOf(
              input
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''),
            ) >= 0
        )
      }}
      defaultValue={selected}
      value={value}
    >
      {options.map(({ value, label, key, ...opts }) => (
        <Option value={value} key={key} label={label} {...opts}>
          {label}
        </Option>
      ))}
    </AntdSelect>
  )
}

export default Select
