import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Tooltip } from 'antd'

import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons'

import { addQuantity, removeQuantity } from '../../redux/quantities/actions'
import { getFilterValueByKey } from '../../redux/filters/reducer'
import {
  getTransferQuantity,
  getStoreManagerRequestsQuantity,
} from '../../redux/quantities/reducer'

import Can from '../Can'

import * as S from './styles'
import { useStores } from '../StoresProvider'

const WarehouseCell = ({
  quantity,
  SKU,
  internalId,
  warehouseId,
  quantityOnOrder,
}) => {
  const dispatch = useDispatch()
  const { getStoreSublocations } = useStores()
  const stores = useSelector(getFilterValueByKey('store'))

  let storeId
  if (stores && stores.length === 1) {
    storeId = stores[0]
  }

  const sublocations = getStoreSublocations(storeId)

  const sublocation = sublocations.find(
    ({ sourceWarehouse }) => sourceWarehouse === warehouseId,
  )

  let destinationId = storeId
  if (sublocation) {
    destinationId = sublocation.id
  }

  const totalDiff = useSelector(getTransferQuantity({ SKU, warehouseId }))
  const currentStoreDiff = useSelector(
    getTransferQuantity({ SKU, warehouseId, storeId: destinationId }),
  )

  const requests = useSelector(
    getStoreManagerRequestsQuantity({ SKU, storeId: destinationId }),
  )

  return (
    <S.Cell changed={currentStoreDiff > 0}>
      <Can
        permission="write:transferorders"
        yes={() => {
          return (
            <S.CellAddButton
              onClick={() =>
                quantity - totalDiff > 0 &&
                dispatch(
                  addQuantity({
                    SKU,
                    storeId: destinationId,
                    internalId,
                    quantity: 1,
                    warehouseId,
                  }),
                )
              }
              disabled={quantity - totalDiff === 0}
              title="Transfer from this warehouse"
            >
              <PlusCircleFilled />
            </S.CellAddButton>
          )
        }}
      />
      <Can
        permission="write:transferorders"
        no={() => {
          return (
            <Can
              permission="write:requests"
              no={() => {
                return (
                  <TooltipCell
                    currentStoreDiff={currentStoreDiff}
                    quantityOnOrder={quantityOnOrder}
                    quantity={quantity}
                    totalDiff={totalDiff}
                  />
                )
              }}
              yes={() => {
                return <S.CellValue>{quantity - totalDiff}</S.CellValue>
              }}
            />
          )
        }}
        yes={() => {
          return (
            <TooltipCell
              currentStoreDiff={currentStoreDiff}
              quantityOnOrder={quantityOnOrder}
              quantity={quantity}
              totalDiff={totalDiff}
            />
          )
        }}
      />
      <Can
        permission="write:transferorders"
        yes={() => {
          return (
            <S.CellRemoveButton
              onClick={() =>
                currentStoreDiff - requests > 0 &&
                dispatch(
                  removeQuantity({
                    SKU,
                    storeId: destinationId,
                    internalId,
                    quantity: 1,
                    warehouseId,
                  }),
                )
              }
              disabled={currentStoreDiff - requests === 0}
              title="Transfer to this warehouse"
            >
              <MinusCircleFilled />
            </S.CellRemoveButton>
          )
        }}
      />
    </S.Cell>
  )
}

const TooltipCell = ({
  currentStoreDiff,
  quantityOnOrder,
  quantity,
  totalDiff,
}) => {
  return (
    <Tooltip
      title={
        <>
          {currentStoreDiff > 0 && (
            <div>
              Transfered from this warehouse:{' '}
              <S.CellDiff alert>{currentStoreDiff}</S.CellDiff>
            </div>
          )}

          <div>
            Coming to this warehouse: <S.CellDiff>{quantityOnOrder}</S.CellDiff>
          </div>
        </>
      }
    >
      <S.CellValue>{quantity - totalDiff}</S.CellValue>
    </Tooltip>
  )
}

export default WarehouseCell
