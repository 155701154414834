import {
  ADD_QUANTITY,
  REMOVE_QUANTITY,
  REMOVE_STORE_MANAGER_REQUEST,
} from './types'
import { createSelector } from 'reselect'

import uniqBy from 'lodash.uniqby'

const quantitiesReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_QUANTITY:
      const { requestId: requestIdAdding } = action.payload

      if (requestIdAdding) {
        const alreadyInStore = state.find(
          ({ requestId }) => requestIdAdding === requestId,
        )

        if (alreadyInStore) {
          console.log(
            `You are putting in redux in item that is already here. What are you doing wrong? Request ID: ${requestIdAdding}`,
          )
          return state
        }
      }
      return [...state, action.payload]
    case REMOVE_QUANTITY:
      return [...state, action.payload]

    case REMOVE_STORE_MANAGER_REQUEST:
      const { storeManagerRequestId } = action.payload
      return state.filter(
        ({ requestId }) => requestId !== storeManagerRequestId,
      )

    default:
      return state
  }
}

export default quantitiesReducer

export const getQuantities = state => (state ? state.quantities : [])

const utilityFunc =
  ({
    storeId: selectedStoreID,
    SKU: selectedSKU,
    warehouseId: selectedWarehouseID,
    isRequest,
  }) =>
  quantities => {
    const filteredQuantities = quantities.filter(
      ({ storeId, SKU, warehouseId, requestId }) =>
        (selectedStoreID ? selectedStoreID === storeId : true) &&
        SKU === selectedSKU &&
        (selectedWarehouseID ? selectedWarehouseID === warehouseId : true) &&
        (isRequest ? (requestId ? true : false) : true),
    )

    return filteredQuantities.reduce((acc, { quantity }) => {
      return acc + quantity
    }, 0)
  }

export const getTransferQuantity = ({ storeId, SKU, warehouseId }) =>
  createSelector(
    getQuantities,
    utilityFunc({
      storeId,
      SKU,
      warehouseId,
    }),
  )

export const getStoreManagerRequestsQuantity = ({
  storeId,
  SKU,
  warehouseId,
}) =>
  createSelector(
    getQuantities,
    utilityFunc({
      storeId,
      SKU,
      warehouseId,
      isRequest: true,
    }),
  )

export const getRowTransferQuantity = ({ storeId: currentStoreId, SKUs }) =>
  createSelector(getQuantities, quantities => {
    const filteredQuantities = quantities.filter(({ storeId, SKU }) => {
      return storeId === currentStoreId && SKUs.includes(SKU)
    })

    return filteredQuantities.reduce((acc, { quantity }) => {
      return acc + quantity
    }, 0)
  })

export const getColTransferQuantity = ({ storeIds, SKU: currentSKU }) =>
  createSelector(getQuantities, quantities => {
    const filteredQuantities = quantities.filter(
      ({ storeId, SKU }) => SKU === currentSKU && storeIds.includes(storeId),
    )

    return filteredQuantities.reduce((acc, { quantity }) => {
      return acc + quantity
    }, 0)
  })

export const getTotalTransferQuantity = ({ storeIds, SKUs }) =>
  createSelector(getQuantities, quantities => {
    const filteredQuantities = quantities.filter(({ storeId, SKU }) => {
      return storeIds.includes(storeId) && SKUs.includes(SKU)
    })

    return filteredQuantities.reduce((acc, { quantity }) => {
      return acc + quantity
    }, 0)
  })

export const getAggregatedQuantities = createSelector(
  getQuantities,
  quantities => {
    if (!quantities) {
      return []
    }

    const uniqueQuantities = uniqBy(
      quantities,
      el => `${el.SKU}_${el.storeId}_${el.warehouseId}`,
    )

    return uniqueQuantities
      .map(quantity => {
        const { storeId, SKU, warehouseId } = quantity

        // Removing isRequest and id from aggregated
        const { requestId, ...quant } = quantity

        return {
          ...quant,
          quantity: utilityFunc({ storeId, SKU, warehouseId })(quantities),
          requests: utilityFunc({
            storeId,
            SKU,
            warehouseId,
            isRequest: true,
          })(quantities),
          requestIds: quantities.reduce((acc, curr) => {
            const {
              requestId: id,
              warehouseId: requestWarehouseId,
              storeId: requestStoreId,
              SKU: requestSKU,
            } = curr
            if (
              id &&
              requestWarehouseId === warehouseId &&
              storeId === requestStoreId &&
              SKU === requestSKU
            ) {
              acc.push(id)
            }
            return acc
          }, []),
        }
      })
      .filter(({ quantity }) => !!quantity)
  },
)

export const getTransferOrders = ({ to = null } = {}) =>
  createSelector(getAggregatedQuantities, aggregatedQuantities => {
    if (!aggregatedQuantities || aggregatedQuantities.length === 0) return []

    const transferOrderTemplates = uniqBy(
      aggregatedQuantities,
      el => `${el.storeId}_${el.warehouseId}`,
    )

    const allTransferOrders = transferOrderTemplates.map(
      ({ warehouseId, storeId }) => {
        const items = aggregatedQuantities.filter(
          quantity =>
            quantity.warehouseId === warehouseId &&
            quantity.storeId === storeId,
        )

        return {
          from: warehouseId,
          to: storeId,
          items,
          requestIds: items.reduce(
            (acc, { requestIds }) => [...acc, ...requestIds], // Metto tutti i requestIds in un'unica array piatta prendendola da ogni singolo record
            [],
          ),
        }
      },
    )

    if (to) {
      return allTransferOrders.filter(transferOrder => {
        return to.includes(transferOrder.to)
      })
    }

    return allTransferOrders
  })

export const getStoreManagerRequestById = createSelector(
  getQuantities,
  quantities => id => quantities.find(({ requestId }) => requestId === id),
)
