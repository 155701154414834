import React, { useState, useEffect } from 'react'
import Confetti from 'react-dom-confetti'
import { Button } from 'antd'

import { createTransferOrder } from './utility'
import { useAuth0 } from '../../auth0-wrapper'
import { useStores } from '../StoresProvider'

const warehouseNames = {
  2: '00',
  3: '02',
  4: '03',
}

const Create = ({ transferOrder, onCreate }) => {
  const [done, setDone] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState()

  const { getTokenSilently } = useAuth0()
  const { getStoreName } = useStores()

  useEffect(() => {
    getTokenSilently().then(token => setToken(token))
  }, [getTokenSilently])

  return (
    <Button
      type="primary"
      loading={loading}
      disabled={done}
      key="list-loadmore-more"
      onClick={() => {
        setLoading(true)
        createTransferOrder({ transferOrder, token })
          .then(response => {
            setDone(true)
            setLoading(false)

            typeof onCreate === 'function' &&
              response.data &&
              onCreate({
                id: response.data.internalId,
                locationName: transferOrder.name,
                warehouseName:
                  warehouseNames[transferOrder.from] ||
                  getStoreName(transferOrder.from),
                warehouseId: transferOrder.from,
              })
          })
          .catch(err => {
            console.error(err)
            setLoading(false)
          })
      }}
    >
      Create{done ? 'd' : ''}
      <Confetti
        active={done}
        config={{
          angle: 90,
          spread: '265',
          startVelocity: 45,
          elementCount: '131',
          dragFriction: 0.1,
          duration: '1110',
          stagger: '0',
          width: '5px',
          height: '5px',
          colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
        }}
      />
    </Button>
  )
}

export default Create
