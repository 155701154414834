import {
  ADD_QUANTITY,
  REMOVE_QUANTITY,
  REMOVE_STORE_MANAGER_REQUEST,
} from './types'

export const addQuantity = ({ quantity, ...rest }) => ({
  type: ADD_QUANTITY,
  payload: { quantity, ...rest },
})

export const removeQuantity = ({ quantity, ...rest }) => ({
  type: REMOVE_QUANTITY,
  payload: { quantity: -quantity, ...rest },
})

export const removeStoreManagerRequest = ({
  storeManagerRequestId,
  ...rest
}) => ({
  type: REMOVE_STORE_MANAGER_REQUEST,
  payload: { storeManagerRequestId, ...rest },
})
