import React, { useMemo } from 'react'

import { useSelector } from 'react-redux'

import { getTotalTransferQuantity } from '../../redux/quantities/reducer'

import * as S from './styles'

import StoreTotalCell from '../StoreTotalCell'

const StoreTotalRow = ({ rowId, storeIds, locationName, sizes, columns }) => {
  const isStoreEmpty = useMemo(() => {
    return (
      sizes.reduce(
        (total, { quantityAvailable }) => total + quantityAvailable,
        0,
      ) === 0
    )
  }, [sizes])

  const rowTotal = sizes.reduce(
    (acc, { quantityAvailable }) => quantityAvailable + acc,
    0,
  )

  const totalDiff = useSelector(
    getTotalTransferQuantity({ storeIds, SKUs: columns.map(({ SKU }) => SKU) }),
  )

  return (
    <>
      <S.IDCell alert={isStoreEmpty}>{rowId || 'Tot'}</S.IDCell>
      <S.BoldCell first={true} alert={isStoreEmpty}>
        {locationName}
      </S.BoldCell>
      {columns.map(({ name, id, internalId, SKU }) => {
        const cell = sizes.find(({ value }) => value === name)
        const { quantityAvailable, quantityOnOrder } = cell || {
          quantityAvailable: 0,
          quantityOnOrder: 0,
        }

        return (
          <StoreTotalCell
            key={id || SKU}
            quantity={quantityAvailable}
            quantityOnOrder={quantityOnOrder}
            SKU={SKU}
            storeIds={storeIds}
            alert={isStoreEmpty}
            internalId={internalId}
          />
        )
      })}
      <S.BoldCell
        alert={isStoreEmpty}
        style={{ justifyContent: 'center', borderLeft: '1px solid #eee' }}
      >
        {rowTotal + totalDiff}
      </S.BoldCell>
    </>
  )
}

export default StoreTotalRow
