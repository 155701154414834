import React, { useState } from 'react'
import { Form, Row, Col, Button } from 'antd'
import { PaperSheet, PaperButtonsWrapper, InputWithError } from './styles'
import { useAuth0 } from '../../auth0-wrapper'

import axios from 'axios'

const LabelForm = () => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { getTokenSilently } = useAuth0()

  const onFinish = async values => {
    const skus = Object.values(values)

    const token = await getTokenSilently()

    setLoading(true)
    axios({
      url: `${
        process.env.REACT_APP_BACKEND_ENDPOINT
      }/api/label?skus=${skus.join(',')}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'labels.pdf')
        document.body.appendChild(link)
        link.click()
        form.resetFields()
        setLoading(false)
      })
      .catch(async error => {
        const text = await error.response.data.text()

        const errors = JSON.parse(text)

        setErrors(errors.errors)

        setLoading(false)
      })
  }

  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
      <PaperSheet>
        <Row>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(i => {
            const err =
              errors &&
              errors.filter(({ position }) => position === i - 1).length > 0

            return (
              <Col span={12} key={i}>
                <Form.Item name={`label${i}`}>
                  <InputWithError
                    {...(err && {
                      onChange: () =>
                        setErrors(
                          errors.filter(({ position }) => position !== i - 1),
                        ),
                      $hasError: err,
                    })}
                    placeholder={`Label ${i}`}
                  />
                </Form.Item>
              </Col>
            )
          })}
        </Row>
      </PaperSheet>
      <PaperButtonsWrapper>
        <Row gutter={[8, 8]}>
          <Col span={12} offset={12} style={{ textAlign: 'right' }}>
            <Button
              onClick={() => {
                form.resetFields()
              }}
            >
              Clear
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 8 }}
              loading={loading}
              disabled={loading}
            >
              Create label
            </Button>
          </Col>
        </Row>
      </PaperButtonsWrapper>
    </Form>
  )
}

export default LabelForm
