import { Input } from 'antd'
import styled, { css } from 'styled-components'

export const PaperSheet = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  > .ant-row {
    background: white;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    width: 21cm;
    height: 29.7cm;
    padding: 1.2cm 0.4cm;
  }

  input {
    width: 9.91cm;
    height: 3.4cm;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const PaperButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;

  > .ant-row {
    width: 21cm;
  }
`
export const InputWithError = styled(Input)`
  ${({ ...props }) =>
    props.$hasError &&
    css`
      background-color: #fff2f0 !important;
      border: 1px solid #ffccc7 !important;
    `}
`
