import styled from 'styled-components/macro'

import { Drawer as AntDrawer } from 'antd'

export const Drawer = styled(AntDrawer)`
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
  }

  .ant-drawer-body {
    padding: 0 24px;
    margin-bottom: 53px;
  }
`

export const NoContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
