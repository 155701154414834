import React, { useState, useEffect, useContext, useCallback } from 'react'

import axios from 'axios'

import { useAuth0 } from '../../auth0-wrapper'

import { useSelector, useDispatch } from 'react-redux'

import { useProducts } from '../ProductsProvider'

import { getFilterValueByKey } from '../../redux/filters/reducer'

import { addQuantity } from '../../redux/quantities/actions'
import { message } from 'antd'
import { useStores } from '../StoresProvider'

const fetchStoreManagerRequests = (token, params) =>
  axios
    .get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/store-manager-requests`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      },
    )
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)

      return []
    })

const fetchTotal = (token, params) =>
  axios
    .get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/store-manager-requests/total`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      },
    )
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)

      return 0
    })

export const StoreManagerRequestContext = React.createContext()
export const useStoreManagerRequests = () =>
  useContext(StoreManagerRequestContext)

const isSizeAvailable = ({
  inventoryLocations,
  internalId,
  locationId,
  minQuantity = 1,
}) => {
  const { sizes } = inventoryLocations[locationId] || {}
  const size =
    sizes &&
    sizes.filter(({ quantityAvailable, internalId: sinternalId }) => {
      return quantityAvailable >= minQuantity && sinternalId === internalId
    })

  return size && size.length > 0
}

export const StoreManagerRequestProvider = ({
  children,
  fetchTransferOrderRequests,
}) => {
  const [loading, setLoading] = useState(false)
  const [storeManagerRequests, setStoreManagerRequests] = useState([])
  const store = useSelector(getFilterValueByKey('store'))
  const { getProductAvailabilities } = useProducts()
  const { getTokenSilently } = useAuth0()
  const dispatch = useDispatch()

  const { getStoreName, stores, getStoreSublocations } = useStores()

  useEffect(() => {
    const fn = async () => {
      setLoading(false)
      const token = await getTokenSilently()

      setStoreManagerRequests(
        await fetchStoreManagerRequests(token, {
          fulfilled: 0,
          deleted: 0,
          denied: 0,
          storeId: store,
        }).catch(() => false),
      )

      setLoading(true)
    }

    fn()
  }, [getTokenSilently, store])

  const getStoreManagerRequests = useCallback(
    params =>
      getTokenSilently().then(token =>
        fetchStoreManagerRequests(token, params),
      ),
    [getTokenSilently],
  )

  const getTotal = useCallback(
    (params = {}) =>
      getTokenSilently().then(token => fetchTotal(token, params)),
    [getTokenSilently],
  )

  const getStoremanagerRequest = useCallback(
    id => storeManagerRequests.find(s => s.id === id),
    [storeManagerRequests],
  )

  useEffect(() => {
    if (!fetchTransferOrderRequests) return

    const fn = async () => {
      if (!stores || stores.length === 0) return

      if (!storeManagerRequests || storeManagerRequests.length === 0) return

      message.info(`Checking store manager requests...`)
      for (let request of storeManagerRequests) {
        const { SKU, storeId, productId, id } = request

        // Per ciascuna delle richieste controllo se il prodotto è disponibile su FIEGE (2,3,4)
        const requestedProduct = await getProductAvailabilities({
          productId,
        })

        const requestedProductParentId = Object.keys(requestedProduct)[0]
        const realRequestedProduct = requestedProduct[requestedProductParentId]

        // Se il prodotto non esiste su Netsuite
        if (!realRequestedProduct) {
          console.error(`Requested product ${productId} does not exist!`)
          continue
        }

        const { inventoryLocations } = realRequestedProduct

        // Cerco prima in FIEGE 02
        let locationId = '3' // FIEGE 02
        if (
          !isSizeAvailable({
            internalId: productId,
            locationId,
            inventoryLocations,
          })
        ) {
          locationId = '2'
          if (
            !isSizeAvailable({
              inventoryLocations,
              internalId: productId,
              locationId,
              minQuantity: 3,
            }) // Poi in FIEGE 00 con la quantity minima a 3
          ) {
            console.log('The product requested is not available', SKU)
            continue
          }
        }

        const sublocations = getStoreSublocations(storeId)

        const sublocation = sublocations.find(
          ({ sourceWarehouse }) => sourceWarehouse === locationId,
        )

        let destinationId = storeId
        if (sublocation) {
          destinationId = sublocation.id
        }

        // Aggiungo il prodotto al TO
        dispatch(
          addQuantity({
            SKU,
            storeId: destinationId,
            internalId: productId,
            warehouseId: locationId,
            quantity: 1,
            requestId: id,
          }),
        )
      }

      message.success(
        `Store manager requests loaded for ${getStoreName(store[0])}`,
      )
    }

    fn()
  }, [
    dispatch,
    fetchTransferOrderRequests,
    getProductAvailabilities,
    getStoreName,
    getStoreSublocations,
    store,
    storeManagerRequests,
    stores,
  ])

  return (
    <StoreManagerRequestContext.Provider
      value={{
        storeManagerRequests,
        loading,
        getStoreManagerRequests,
        getTotal,
        getStoremanagerRequest,
      }}
    >
      {children}
    </StoreManagerRequestContext.Provider>
  )
}
