import React, { useEffect } from 'react'

import { Checkbox, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { useStores } from '../StoresProvider'

import { getFilterValueByKey } from '../../redux/filters/reducer'
import { setFilter } from '../../redux/filters/actions'

const makeOption = ({ id, values: { name } }) => ({
  key: id,
  value: id,
  label: name,
})

const MultiStoresSelect = ({ disabled }) => {
  const { stores, getStoreSublocations } = useStores()
  const dispatch = useDispatch()

  const selectedStores = useSelector(getFilterValueByKey('store'))

  useEffect(() => {
    const sublocations = selectedStores.reduce((acc, selectedStore) => {
      const sublocations = getStoreSublocations(selectedStore)

      return [...acc, ...sublocations.map(({ id }) => id)]
    }, [])

    dispatch(
      setFilter({
        key: 'sublocations',
        value: sublocations,
      }),
    )
  }, [dispatch, getStoreSublocations, selectedStores, stores])

  return (
    <Checkbox.Group
      style={{ width: '100%' }}
      value={selectedStores}
      disabled={disabled}
      onChange={e => dispatch(setFilter({ key: 'store', value: e }))}
    >
      <Row>
        {stores &&
          stores.map(makeOption).map(({ key, label, value }) => {
            return (
              <Col span={24} key={key} sm={12} md={8} lg={6} xl={6} xxl={8}>
                <Checkbox value={value}>{label}</Checkbox>
              </Col>
            )
          })}
      </Row>
    </Checkbox.Group>
  )
}

export default MultiStoresSelect
