import styled from 'styled-components/macro'
import { Card as AntCard } from 'antd'

export const Table = styled.div`
  display: grid;
  grid-template-columns: minmax(20px, 70px) minmax(120px, 300px) repeat(
      ${props => props.size - 2},
      1fr
    );
  /* border-top: 1px solid black;
  border-right: 1px solid black; */
  & > div {
    padding: 2px;
    /* border-left: 1px solid black;
    border-bottom: 1px solid black; */
  }
  width: 100%;
  height: 100%;
`

export const Container = styled.div``

export const HeaderCell = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
`

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`

export const LoaderContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`
export const Card = styled(AntCard)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
