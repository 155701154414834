import React, { useState, useEffect, useContext } from 'react'

import axios from 'axios'

import { useAuth0 } from '../../auth0-wrapper'

const getProductNames = token =>
  axios
    .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/product-names`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log(err)
      return false
    })

export const ProductNameContext = React.createContext()
export const useProductNames = () => useContext(ProductNameContext)

export const ProductNameProvider = ({ children }) => {
  const [productNames, setProductNames] = useState([])
  const { getTokenSilently } = useAuth0()
  useEffect(() => {
    const fn = async () => {
      const token = await getTokenSilently()
      const { data } = await getProductNames(token)

      if (!data) return

      setProductNames(
        data.filter(e => {
          return (
            e.values['GROUP(custitem_velprod_model_nameit)'].indexOf('None') ===
              -1 ||
            e.values['GROUP(custitem_velprod_prod_name_it)'].indexOf('None') ===
              -1
          )
        }),
      )
    }

    fn()
  }, [getTokenSilently])

  return (
    <ProductNameContext.Provider value={{ productNames }}>
      {children}
    </ProductNameContext.Provider>
  )
}
