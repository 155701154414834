import React from 'react'

import WarehouseRow from '../WarehouseRow'

const WarehouseSection = ({ warehouses, columns }) => {
  if (!warehouses) {
    return null
  }

  return Object.values(warehouses).map(warehouse => {
    if (!warehouse) {
      return null
    }

    const {
      sizes,
      inventoryLocation: { id, value: locationName },
    } = warehouse

    return (
      <WarehouseRow
        key={`warehouse_${id}`}
        warehouseId={id}
        warehouses={warehouses}
        locationName={locationName}
        sizes={sizes}
        columns={columns}
      />
    )
  })
}

export default WarehouseSection
