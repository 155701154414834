import { TOGGLE_SIDEBAR, OPEN_SIDEBAR, CLOSE_SIDEBAR } from './types'

const handleSidebarAction = (state = false, action) => {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return true
    case CLOSE_SIDEBAR:
      return false
    case TOGGLE_SIDEBAR:
      return !state
    default:
      return state
  }
}

export default handleSidebarAction

export const getIsSidebarOpen = state => state.sidebar
