import React from 'react'

import { Radio } from 'antd'

import { useDispatch } from 'react-redux'
import { setFilter } from '../../redux/filters/actions'

const ModelCategoryRadio = ({ disabled }) => {
  const dispatch = useDispatch()
  return (
    <Radio.Group
      defaultValue="productModel"
      buttonStyle="solid"
      disabled={disabled}
      onChange={e => {
        dispatch(setFilter({ key: 'searchType', value: e.target.value }))

        if (e.target.value === 'productModel') {
          dispatch(setFilter({ key: 'productName', value: null }))
        }
      }}
    >
      <Radio.Button value="productName">Nome</Radio.Button>
      <Radio.Button value="productModel">Categoria</Radio.Button>
      <Radio.Button value="productParent">Parent</Radio.Button>
    </Radio.Group>
  )
}

export default ModelCategoryRadio
