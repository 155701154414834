import styled from 'styled-components/macro'

export const CellButton = styled.div`
  display: flex;
  cursor: pointer;
  color: ${props => (props.disabled ? 'grey' : '#185674')};
  font-size: 1.2rem;
  opacity: 0;
  flex-grow: 1;
`

export const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  background-color: ${props => (props.changed ? '#ddd' : '#fff')};

  &:hover {
    background-color: #e8e8e8;
    & ${CellButton} {
      opacity: 1;
    }
  }
`

export const CellAddButton = styled(CellButton)`
  justify-content: flex-end;
`

export const CellRemoveButton = styled(CellButton)`
  justify-content: flex-start;
`

export const CellValue = styled.div`
  padding: 0.25rem;
  flex-grow: 1;
  text-align: center;
`

export const CellDiff = styled.span`
  color: ${props => (props.alert ? 'red' : 'white')};
  font-weight: bold;
`
