import { SET_FILTER, REMOVE_FILTER } from './types'

export const setFilter = ({ key, value }) => ({
  type: SET_FILTER,
  payload: { key, value },
})

export const removeFilter = ({ key }) => ({
  type: REMOVE_FILTER,
  payload: { key },
})
