import React, { useState, useRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { Tooltip, Modal, Form, Input, message } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import ShoeCard from '../ShoeCard'

import Can from '../Can'

import {
  getTransferQuantity,
  getStoreManagerRequestsQuantity,
} from '../../redux/quantities/reducer'

import * as S from './styles'
import { useProducts } from '../ProductsProvider/ProductsProvider'
import axios from 'axios'
import { useAuth0 } from '../../auth0-wrapper'
import { useStores } from '../StoresProvider'

const createStoreManagerRequest = ({ token, ...requestData }) => {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/store-manager-request`,
    requestData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

const StoreCell = ({
  quantity,
  SKU,
  storeId,
  alert,
  quantityOnOrder,
  internalId,
  first,
}) => {
  const diff = useSelector(getTransferQuantity({ SKU, storeId }))

  const requests = useSelector(
    getStoreManagerRequestsQuantity({ SKU, storeId }),
  )

  const { getStoreLanguage } = useStores()

  const { getTokenSilently, getUserMetadata } = useAuth0()

  const { getProduct } = useProducts()
  const [isHover, setIsHover] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const formRef = useRef(null)

  const product = getProduct({ SKU })
  const { storeId: userStoreId } = getUserMetadata()

  const { imageUrl, name } = product

  const handleCreate = () => {
    const { form } = formRef.current
    form.validateFields().then(async values => {
      const { customerEmail, comments, customerFirstName, customerLastName } =
        values

      const token = await getTokenSilently()

      setConfirmLoading(true)
      await createStoreManagerRequest({
        token,
        SKU,
        storeId,
        productId: internalId,
        customerEmail,
        comments,
        customerFirstName,
        customerLastName,
        language: getStoreLanguage(storeId),
      })
        .then(() => {
          form.resetFields()
          message.success(`Item ${name} (${SKU}) requested`)
          setIsVisible(false)
        })
        .catch(err => {
          console.log(err)
          message.error(err.response.message)
          setConfirmLoading(false)
        })
      setConfirmLoading(false)
    })
  }

  const handleCancel = () => {
    setIsVisible(false)
  }

  return (
    <>
      <Tooltip
        title={
          <>
            {diff > 0 && (
              <>
                Added: <S.CellDiff>+{diff}</S.CellDiff>
              </>
            )}
            <div>
              Coming to this store: <S.CellDiff>{quantityOnOrder}</S.CellDiff>
            </div>
            {requests > 0 && (
              <div>
                Requested from this store: <S.CellDiff>{requests}</S.CellDiff>
              </div>
            )}
          </>
        }
      >
        <S.Cell
          style={{
            cursor: isHover && userStoreId === storeId ? 'pointer' : 'default',
          }}
          first={first}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          alert={alert && diff === 0}
          changed={diff - requests > 0}
          requests={requests > 0}
          onClick={() => userStoreId === storeId && setIsVisible(true)}
        >
          <S.CellValue>
            <Can
              permission="write:requests"
              yes={() => {
                return isHover && userStoreId === storeId ? (
                  <PlusOutlined type="plus" />
                ) : (
                  quantity + diff
                )
              }}
              no={() => quantity + diff}
            />
          </S.CellValue>
        </S.Cell>
      </Tooltip>
      <Can
        permission="write:requests"
        yes={() => {
          return (
            <ShoesRequestForm
              ref={formRef}
              visible={isVisible}
              onCancel={handleCancel}
              onCreate={handleCreate}
              image={imageUrl}
              productName={name}
              SKU={SKU}
              confirmLoading={confirmLoading}
            />
          )
        }}
      />
    </>
  )
}

const ShoesRequestForm = React.forwardRef(
  (
    { visible, onCancel, onCreate, image, productName, SKU, confirmLoading },
    ref,
  ) => {
    const [form] = Form.useForm()

    useImperativeHandle(ref, () => ({
      form,
    }))

    return (
      <Modal
        visible={visible}
        title={`Order ${productName} for a client (${SKU})`}
        okText="Request item"
        onCancel={onCancel}
        onOk={onCreate}
        confirmLoading={confirmLoading}
        centered
      >
        <ShoeCard
          image={image}
          name={productName}
          value={SKU}
          zoom={false}
          style={{ marginBottom: '1rem' }}
        />
        <Form
          form={form}
          layout="horizontal"
          labelCol={{
            xs: { span: 24 },
            sm: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
          }}
        >
          <Form.Item label="Customer first name" name="customerFirstName">
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Customer last name" name="customerLastName">
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Customer email"
            extra="Check this email address with the client!"
            name="customerEmail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid email',
              },
              {
                required: true,
                message: "Please enter customer's email",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please enter a comment',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    )
  },
)

export default StoreCell
