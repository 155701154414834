import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Select from '../Select'

import { useStores } from '../StoresProvider'
import { useProducts } from '../ProductsProvider'

import { setFilter } from '../../redux/filters/actions'

import { getFilterValueByKey } from '../../redux/filters/reducer'

const makeOption = ({ id, values: { name } }) => ({
  key: id,
  value: id,
  label: name,
})

const StoreSelect = () => {
  const { stores, getStoreSublocations } = useStores()
  const { loading } = useProducts()
  const dispatch = useDispatch()

  const selectedStores = useSelector(getFilterValueByKey('store'))

  useEffect(() => {
    const sublocations = getStoreSublocations(selectedStores[0])
    dispatch(
      setFilter({
        key: 'sublocations',
        value: sublocations.map(({ id }) => id),
      }),
    )
  }, [dispatch, getStoreSublocations, selectedStores, stores])

  return (
    <Select
      options={stores.map(makeOption)}
      onChange={val => dispatch(setFilter({ key: 'store', value: [val] }))}
      disabled={loading}
      value={selectedStores}
    />
  )
}

export default StoreSelect
