import styled from 'styled-components/macro'

export const BoldCell = styled.div`
  font-weight: bold;
  ${props => props.alert && 'color: red;'}
  ${props => props.first === true && 'border-top: 1px solid #e8e8e8;'}
  display: flex;
  align-items: center;
`
export const IDCell = styled(BoldCell)`
  justify-content: center;
`
