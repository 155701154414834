import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Select from '../Select'

import { useProductNames } from '../ProductNameProvider'

import { setFilter } from '../../redux/filters/actions'

import { getFilterValueByKey } from '../../redux/filters/reducer'

const makeOption = ({
  values: {
    'GROUP(custitem_velprod_prod_name_it)': productName,
    'GROUP(custitem_velprod_model_nameit)': productModel,
    'GROUP(CUSTITEM_VELPROD_MODEL.internalid)': id,
  },
}) => ({
  label: `${productName} - ${productModel}`,
  value: `${id[0] && id[0].value}_${productName}`,
  key: `${id[0] && id[0].value}_${productName}`,
  id: id[0] && id[0].value,
  productName,
})

const ProductNameSelect = ({ disabled }) => {
  const { productNames } = useProductNames()
  const dispatch = useDispatch()

  const options = useMemo(
    () => productNames.map(makeOption),
    [productNames],
  ).sort(({ label }) => label)

  const productName = useSelector(getFilterValueByKey('productName'))
  const productModel = useSelector(getFilterValueByKey('productModel'))

  return (
    <Select
      placeholder="Select a product by name"
      options={options}
      value={
        productName && productName ? `${productModel}_${productName}` : null
      }
      disabled={disabled}
      onChange={value => {
        dispatch(
          setFilter({
            key: 'productModel',
            value: options.find(e => e.value === value).id,
          }),
        )
        dispatch(
          setFilter({
            key: 'productName',
            value: options.find(e => e.value === value).productName,
          }),
        )
      }}
    />
  )
}

export default ProductNameSelect
