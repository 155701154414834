import React, { useState, useEffect, useContext, useCallback } from 'react'

import axios from 'axios'

import { useAuth0 } from '../../auth0-wrapper'
import { useSelector } from 'react-redux'
import { getFilterValueByKey } from '../../redux/filters/reducer'

export const StoresContext = React.createContext()
export const useStores = () => useContext(StoresContext)

const transferOrderDeliveryDays = {
  13: 4, // Roma prati, giovedì
  20: 4, // Torino giovedì
  25: 5, // Roma centro / campo marzio, venerdì
  26: 5, // Archimede, venerdì
  28: 4, // Garibaldi, giovedì
  29: 5, // Firenze, venerdì
  30: 5, // Bologna, venerdì
  31: 5, // Parigi (OLD), venerdì
  32: false, // London (OLD), no giorno
  35: false, // NY (OLD) non dovrebbe esserci, ma deve esserci
  40: 5, // Palermo venerdì
  48: false, // London, no giorno
  49: false, // NY, no giorno
  5: 4, // Sempione, giovedì
  50: 5, // Parigi, venerdì
  52: 5, // Giovine Italia, venerdì
  53: 4, // Napoli, giovedì
  55: 5, // Brescia, venerdì
  57: 4, // Torino Carlo Alberto
  60: 5, // Roma Donna
  61: 1, // Rinascente Milano
  63: 5, // Bottega Mercato
  64: false, // Rinascente Roma Fiume Uomo
  80: false, // Rinascente Roma Fiume Donna
  81: 1, // Rinascente Roma Tritone Uomo
  // 173: // Temporary Tortona
  193: 4, // Roma Prati 95
}

const transferOrderPickUpDays = {
  13: 2, // Roma prati
  20: 2, // Torino
  25: 3, // Roma centro / campo marzio
  26: 4, // Archimede,
  28: 3, // Garibaldi
  29: 4, // Firenze
  30: 4, // Bologna
  31: 2, // Parigi (OLD)
  32: 5, // London (OLD)
  35: 5, // NY (OLD)
  40: 3, // Palermo venerdì
  48: 5, // London
  49: 5, // NY
  5: 3, // Sempione
  50: 2, // Parigi
  52: 4, // Giovine Italia
  53: 2, // Napoli
  55: 2, // Brescia
  57: 2, // Torino Carlo Alberto
  60: 3, // Roma Donna
  61: 5, // Rinascente Milano
  63: 4, // Bottega Mercato
  64: 5, // Rinascente Roma Fiume Uomo
  80: 5, // Rinascente Roma Fiume Donna
  81: 5, // Rinascente Roma Tritone Uomo
  // 173: // Temporary Tortona
  193: 2, // Roma Prati 95
}

const transferOrderDeliveryTime = {
  13: '8.30-9.30', // Roma prati
  20: '11.00-13.00', // Torino
  25: '8.30-9.30', // Roma centro / campo marzio
  26: false, // Archimede
  28: '8.30-9.30', // Garibaldi
  29: '11.00-13.00', // Firenze
  30: '10.00-12.00', // Bologna
  31: '8.30-9.30', // Parigi (OLD)
  32: false, // London (OLD), nessun giorno
  35: false, // NY non dovrebbe esserci, ma deve esserci
  40: 'in giornata', // Palermo
  48: false, // London, nessun giorno
  49: false, // NY,
  5: '8.30-9.30', // Sempione
  50: '8.30-9.00', // Parigi
  52: '8.30-9.00', // Giovine Italia
  53: '10.00-10.30', // Napoli
  55: '10.00-12.00', // Brescia
  57: '11.00-13.00', // Torino Carlo Alberto
  60: '8.30-9.30', // Roma Donna
  61: 'entro le 11.00', // Rinascente Milano
  63: '7.30-7.45', // Bottega Mercato
  64: false, // Rinascente Roma Fiume Uomo
  80: false, // Rinascente Roma Fiume Donna
  81: 'entro le 11.00', // Rinascente Roma Tritone Uomo
  // 173: // Temporary Tortona
  193: '8.30-9.30', // Roma Prati 95
}

const transferOrderPickUpTimes = {
  13: '10.30-11.00', // Roma prati
  20: '14.00-14.30', // Torino
  25: '10.30-11.00', // Roma centro / campo marzio
  26: '', // Archimede
  28: '14.00-14.30', // Garibaldi
  29: '14.00-14.30', // Firenze
  30: '14.00-14.30', // Bologna
  31: '14.00-14.30', // Parigi (OLD)
  32: '14.00-14.30', // London (OLD), nessun giorno
  35: '14.00-14.30', // NY non dovrebbe esserci, ma deve esserci
  40: '14.00-14.30', // Palermo
  48: '14.00-14.30', // London, nessun giorno
  49: '14.00-14.30', // NY,
  5: '14.00-14.30', // Sempione
  50: '12.00', // Parigi
  52: '14.00-14.30', // Giovine Italia
  53: '14.00-14.30', // Napoli
  55: '14.00-14.30', // Brescia
  57: '14.00-14.30', // Torino Carlo Alberto
  60: '10.30-11.00', // Roma Donna
  61: '14.00-14.30', // Rinascente Milano
  63: '14.00-14.30', // Bottega Mercato
  64: '14.00-14.30', // Rinascente Roma Fiume Uomo
  80: '14.00-14.30', // Rinascente Roma Fiume Donna
  81: '14.00-14.30', // Rinascente Roma Tritone Uomo
  // 173: // Temporary Tortona
  193: '10.30-11.00', // Roma Prati 95
}

const transferOrderDeliveryShippingCurrier = {
  13: 'NEX, attendo i dettagli per il ritiro', // Roma prati, venerdì
  20: 'NEX, attendo i dettagli per il ritiro', // Torino venerdì
  25: 'NEX, attendo i dettagli per il ritiro', // Roma centro / campo marzio, giovedì
  26: 'SDA, attendo i dettagli per LDV, ', // Archimede, venerdì
  28: 'NEX, attendo i dettagli per il ritiro', // Garibaldi, giovedì
  29: 'NEX, attendo i dettagli per il ritiro', // Firenze, venerdì
  30: 'NEX, attendo i dettagli per il ritiro', // Bologna, venerdì
  31: 'Transitaladiem, attendo i dettagli per il ritiro', // Parigi, venerdì
  32: 'DHL Economy', // London, venerdì
  35: 'FedEx', // NY non dovrebbe esserci, ma deve esserci
  40: 'DHL', // Palermo
  48: 'DHL Economy', // London, 'DHL Economy'
  49: 'FedEx', // NY,
  5: 'NEX, attendo i dettagli per il ritiro', // Sempione, giovedì
  50: 'Transitaladiem, attendo i dettagli per il ritiro', // Parigi
  52: 'NEX, attendo i dettagli per il ritiro', // Giovine Italia
  53: 'NEX, attendo i dettagli per il ritiro', // Napoli
  55: 'NEX, attendo i dettagli per il ritiro', // Brescia
  57: 'NEX, attendo i dettagli per il ritiro', // Torino Carlo Alberto
  60: 'NEX, attendo i dettagli per il ritiro', // Roma Donna
  61: 'SDA, attendo i dettagli per LDV', // Rinascente Milano
  63: 'NEX, attendo i dettagli per il ritiro', // Bottega Mercato
  64: 'SDA, attendo i dettagli per LDV', // Rinascente Roma Fiume Uomo
  80: 'SDA, attendo i dettagli per LDV', // Rinascente Roma Fiume Donna
  81: 'SDA, attendo i dettagli per LDV', // Rinascente Roma Tritone Uomo
  // 173: // Temporary Tortona
  193: 'NEX, attendo i dettagli per il ritiro', // Roma Prati 95
}

const transferOrderApparelAdditionalCare = {
  13: false, // Roma prati, venerdì
  20: false, // Torino venerdì
  25: false, // Roma centro / campo marzio, giovedì
  26: false, // Archimede, venerdì
  28: false, // Garibaldi, giovedì
  29: false, // Firenze, venerdì
  30: false, // Bologna, venerdì
  31: false, // Parigi, venerdì
  32: false, // London, venerdì
  35: false, // NY non dovrebbe esserci, ma deve esserci
  40: false, // Palermo
  48: false, // London, 'DHL Economy'
  49: false, // NY,
  5: false, // Sempione, giovedì
  50: false, // Parigi
  52: "Ti chiederei di gestire i capispalla come appesi e non piegati all'interno dei colli", // Giovine Italia
  53: false, // Napoli
  55: "Ti chiederei di gestire i capispalla come appesi e non piegati all'interno dei colli", // Brescia
  57: false, // Torino Carlo Alberto
  60: false, // Roma Donna
  61: false, // Rinascente Milano
  63: "Ti chiederei di gestire i capispalla come appesi e non piegati all'interno dei colli", // Bottega Mercato
  64: false, // Rinascente Roma Fiume Uomo
  80: false, // Rinascente Roma Fiume Donna
  81: false, // Rinascente Roma Tritone Uomo
  // 173: // Temporary Tortona
  193: "Ti chiederei di gestire i capispalla come appesi e non piegati all'interno dei colli", // Roma Prati 95
}

const storeEmails = {
  13: 'bottega.roma@velasca.com',
  20: 'bottega.torino@velasca.com',
  25: 'bottega.campomarzio@velasca.com',
  26: 'bottega.archimede@velasca.com',
  28: 'bottega.garibaldi@velasca.com',
  29: 'bottega.firenze@velasca.com',
  30: 'bottega.bologna@velasca.com',
  31: 'bottega.paris@velasca.com',
  32: 'bottega.london@velasca.com', // London
  35: 'bottega.newyork@velasca.com', // NY (OLD) per ora è Francesca, poi andrà cambiato
  40: 'bottega.palermo@velasca.com', // Palermo
  48: 'bottega.london@velasca.com', // London, inglese
  49: 'bottega.newyork@velasca.com', // NY, inglese
  5: 'bottega.milano@velasca.com', // Sempione
  50: 'bottega.paris@velasca.com', // Parigi, francese
  52: 'bottega.giovineitalia@velasca.com', // Giovine Italia
  53: 'bottega.napoli@velasca.com', // Napoli
  55: 'bottega.brescia@velasca.com', // Brescia
  57: 'bottega.carloalberto@velasca.com', // Torino Carlo Alberto
  60: 'bottega.campomarzio71@velasca.com', // Roma Donna
  61: 'rinascente.milano.uomo@velasca.com', // Rinascente Milano
  63: 'bottega.viamercato@velasca.com', // Bottega Mercato
  64: 'rinascente.romafiume.uomo@velasca.com', // Rinascente Roma Fiume Uomo
  80: 'rinascente.romafiume.donna@velasca.com', // Rinascente Roma Fiume Donna
  81: 'rinascente.tritone.uomo@velasca.com', // Rinascente Roma Tritone Uomo
  173: 'bottega.vintagetortona@velasca.com', // Temporary Tortona
  193: 'bottega.romatl@velasca.com', // Roma Prati 95
}

const storeCCEmails = {
  13: 'daniela.santorum@nipponexpressitalia.com', // Roma Prati
  20: 'daniela.santorum@nipponexpressitalia.com', // Torino
  25: 'daniela.santorum@nipponexpressitalia.com', // Roma Campo Marzio
  26: 'damiano.falleti@velasca.com', // Archimede
  28: 'daniela.santorum@nipponexpressitalia.com', // Garibaldi
  29: 'daniela.santorum@nipponexpressitalia.com', // Firenze
  30: 'daniela.santorum@nipponexpressitalia.com', // Bologna
  31: 'sarah@transitaladiem.com,alex@transitaladiem.com,mauro@transitaladiem.com,traffico@transitaladiem.com', // Parigi OLD
  32: 'mario.milano@velasca.com,amministrazione@velasca.com', // London (old)
  35: '', // NY (OLD) per ora è Francesca, poi andrà cambiato
  40: '', // Palermo
  48: 'mario.milano@velasca.com,amministrazione@velasca.com', // London
  49: '', // NY,
  5: 'daniela.santorum@nipponexpressitalia.com', // Sempione
  50: 'sarah@transitaladiem.com,alex@transitaladiem.com,mauro@transitaladiem.com,traffico@transitaladiem.com', // Parigi
  52: 'daniela.santorum@nipponexpressitalia.com', // Giovine Italia
  53: 'daniela.santorum@nipponexpressitalia.com', // Napoli
  55: 'daniela.santorum@nipponexpressitalia.com', // Brescia
  57: 'daniela.santorum@nipponexpressitalia.com', // Torino Carlo Alberto
  60: 'daniela.santorum@nipponexpressitalia.com', // Roma Donna
  61: 'damiano.falleti@velasca.com', // Rinascente Milano
  63: 'daniela.santorum@nipponexpressitalia.com', // Bottega Mercato
  64: 'damiano.falleti@velasca.com', // Rinascente Roma Fiume Uomo
  80: 'damiano.falleti@velasca.com', // Rinascente Roma Fiume Donna
  81: 'damiano.falleti@velasca.com', // Rinascente Roma Tritone Uomo
  // 173: // Temporary Tortona
  193: 'daniela.santorum@nipponexpressitalia.com', // Roma Prati 95
}

const storeLanguages = {
  13: '25', // Roma prati, italiano
  20: '25', // Torino italiano
  25: '25', // Roma centro / campo marzio, italiano
  26: '25', // Archimede, italiano
  28: '25', // Garibaldi, italiano
  29: '25', // Firenze, italiano
  30: '25', // Bologna, italiano
  31: '16', // Parigi (old), francese
  32: '1', // London (old), inglese
  35: '1', // NY (old), inglese
  40: '25', // Palermo italiano
  48: '1', // London, inglese
  49: '1', // NY, inglese
  5: '25', // Sempione, italiano
  50: '16', // Parigi, francese
  52: '25', // Giovine italia, italiano
  53: '25', // Napoli, italiano
  55: '25', // Brescia, italiano
  57: '25', // Torino Carlo Alberto, italiano
  60: '25', // Roma Donna
  61: '25', // Rinascente Milano
  63: '25', // Bottega Mercato
  64: '25', // Rinascente Roma Fiume Uomo
  80: '25', // Rinascente Roma Fiume Donna
  81: '25', // Rinascente Roma Tritone Uomo
  173: '25', // Temporary Tortona
  193: '25', // Roma Prati 95
}

export const StoresProvider = ({ children }) => {
  const [stores, setStores] = useState([])
  const warehouseType = useSelector(getFilterValueByKey('warehouseType'))

  const { getTokenSilently } = useAuth0()

  const getStoreName = useCallback(
    id => {
      const store = stores.find(store => parseInt(store.id) === parseInt(id))

      if (store) {
        return store?.values?.name
      }

      const storeWithSublocation = stores.find(store => {
        return store?.values?.sublocations?.find(
          s => parseInt(s.id) === parseInt(id),
        )
      })

      const sublocation = storeWithSublocation?.values?.sublocations.find(
        s => parseInt(s.id) === parseInt(id),
      )

      return sublocation?.values?.name?.split(':')[1] || false
    },
    [stores],
  )

  const getStoreClass = useCallback(
    id => {
      // I need to check the store or the sublocations of the store
      const store = stores.find(
        store =>
          parseInt(store.id) === parseInt(id) ||
          store?.values?.sublocations?.find(
            s => parseInt(s.id) === parseInt(id),
          ),
      )
      return (
        store &&
        store.values &&
        store.values['CUSTRECORD_LOCATION_CLASS.internalid'] &&
        store.values['CUSTRECORD_LOCATION_CLASS.internalid'][0] &&
        store.values['CUSTRECORD_LOCATION_CLASS.internalid'][0].value
      )
    },
    [stores],
  )

  const getStoreSublocations = useCallback(
    id => {
      const store = stores.find(store => parseInt(store.id) === parseInt(id))
      return (store?.values?.sublocations || []).map(s => {
        let sourceWarehouse

        const sublocationName = s.values?.name

        // These are the rules to route SKU transfers. Since FIEGE 02 and FIEGE 03 are not linked
        // to the store 02 and 03 sublocations, we use the warehouse name.
        if (sublocationName.includes('02')) {
          sourceWarehouse = warehouseType === 'fiege' ? '3' : '176'
        } else if (sublocationName.includes('03')) {
          sourceWarehouse = warehouseType === 'fiege' ? '4' : '177'
        }

        return { ...s, sourceWarehouse }
      })
    },
    [stores, warehouseType],
  )

  const isStore = useCallback(
    id => {
      const store = stores.find(store => store.id === id)

      if (store) return true

      return false
    },
    [stores],
  )

  const getSubsidiary = useCallback(
    id => {
      const store = stores.find(store => {
        return (
          parseInt(store.id) === parseInt(id) ||
          store.values.sublocations.find(sublocation => {
            return parseInt(sublocation.id) === parseInt(id)
          }) !== undefined
        )
      })

      return (store && store.values && store.values.subsidiaryId) || false
    },
    [stores],
  )

  useEffect(() => {
    const fn = async () => {
      const token = await getTokenSilently()
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/locations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => data)
        .catch(() => [])
      setStores(data)
    }

    fn()
  }, [getTokenSilently])

  return (
    <StoresContext.Provider
      value={{
        stores,
        getStoreName,
        getStoreClass,
        getStoreDeliveryDay: id => transferOrderDeliveryDays[id] || false,
        getStoreEmail: id => storeEmails[id],
        getStoreCCEmail: id => storeCCEmails[id],
        getStoreLanguage: id => storeLanguages[id],
        getStoreDeliveryTime: id => transferOrderDeliveryTime[id] || false,
        getStorePickUpDay: id => transferOrderPickUpDays[id] || false,
        getStoreShippingCurrier: id =>
          transferOrderDeliveryShippingCurrier[id] || 'DHL',
        getApparelAdditionalCare: id =>
          transferOrderApparelAdditionalCare[id] || false,
        getStorePickUpTime: id => transferOrderPickUpTimes[id] || false,
        isStore,
        getSubsidiary,
        getStoreSublocations,
      }}
    >
      {children}
    </StoresContext.Provider>
  )
}
