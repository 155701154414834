import React, { useState, useEffect, useContext, useCallback } from 'react'

import axios from 'axios'

import { useAuth0 } from '../../auth0-wrapper'

const getProductModels = token =>
  axios
    .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/product-models`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

export const ProductModelsContext = React.createContext()
export const useProductModels = () => useContext(ProductModelsContext)

export const ProductModelProvider = ({ children }) => {
  const [productModels, setProductModels] = useState([])
  const getProductModel = useCallback(
    ({ productModelId }) => {
      const productModel = productModels.find(pm => pm.id === productModelId)

      if (!productModel) return {}

      const {
        id,
        values: { name, custrecord_velprod_model_nameit: nameIT },
      } = productModel
      return {
        id,
        name,
        nameIT,
      }
    },
    [productModels],
  )
  const { getTokenSilently } = useAuth0()
  useEffect(() => {
    const fn = async () => {
      const token = await getTokenSilently()
      const p = await getProductModels(token)
      setProductModels(p)
    }

    fn()
  }, [getTokenSilently])

  return (
    <ProductModelsContext.Provider value={{ productModels, getProductModel }}>
      {children}
    </ProductModelsContext.Provider>
  )
}
