import styled from 'styled-components/macro'

import { Table as AntdTable } from 'antd'

export const Table = styled(AntdTable)`
  flex-grow: 1;
  .ant-table-small .ant-table-footer {
    padding: 8px;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .is-request-row {
    background-color: #d8effd;
  }

  .ant-table-title {
    padding-right: 16px;
    padding-left: 16px;
    border: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }

  .ant-table-content {
    border: 1px solid #e8e8e8;
    border-bottom: none;
  }

  .ant-table-content table {
    table-layout: fixed !important;
    border-bottom: none;
  }

  .ant-table-footer {
    border: 1px solid #e8e8e8;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }

  .ant-table-tbody {
    border-radius: 0;
  }
`
