import React, { useEffect, useState } from 'react'

import * as S from './styles'

import { useProducts } from '../ProductsProvider/ProductsProvider'
import { useProductModels } from '../ProductModelProvider'
import { useStoreManagerRequests } from '../StoreManagerRequestProvider'

import { Table } from 'antd'
import { removeStoreManagerRequest } from '../../redux/quantities/actions'
import { useDispatch } from 'react-redux'

const RecapTable = ({ header, items }) => {
  const { getProduct } = useProducts()
  const { getProductModel } = useProductModels()
  const { getStoremanagerRequest } = useStoreManagerRequests()
  const [dataSource, setDataSource] = useState()

  const dispatch = useDispatch()

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'SKU',
      key: 'SKU',
    },
    {
      title: 'Category',
      dataIndex: 'nameIT',
      key: 'nameIT',
    },
    {
      title: 'Product Type',
      dataIndex: ['productType', 'value'],
      key: 'productType',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Requests',
      dataIndex: 'requests',
      key: 'requests',
    },
    {
      title: 'Request',
      dataIndex: 'isRequest',
      render: text => (text ? 'Yes' : ''),
    },
  ]

  useEffect(() => {
    let aaa = []
    for (let { quantity, SKU, requests, requestIds } of items) {
      const { name, productModelId, productType, size } = getProduct({
        SKU,
      })
      const { nameIT } = getProductModel({ productModelId })

      aaa.push({
        key: SKU,
        SKU,
        nameIT,
        name,
        quantity,
        size,
        productType,
        isRequest: requests > 0,
        requests,
        storeManagerRequests: requestIds.map(getStoremanagerRequest),
      })
    }

    setDataSource(aaa)
  }, [getProduct, getProductModel, getStoremanagerRequest, items])

  return (
    <S.Table
      // style={{ flexGrow: 1 }}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      title={header}
      expandRowByClick
      expandable={{
        indentSize: 0,
        rowExpandable: record =>
          record.storeManagerRequests && record.storeManagerRequests.length > 0,
        expandedRowRender: record => {
          const columns = [
            {
              title: 'ID',
              dataIndex: 'id',
              width: 100,
            },
            {
              title: 'Customer First Name',
              dataIndex: 'customerFirstName',
            },
            {
              title: 'Customer Last Name',
              dataIndex: 'customerLastName',
            },
            {
              title: 'Customer email',
              dataIndex: 'customerEmail',
            },
            {
              title: 'Notes',
              dataIndex: 'comments',
            },
            {
              title: 'Created on',
              dataIndex: 'createdAt',
              render: text => new Date(text).toLocaleString(),
            },
            {
              title: 'Remove',
              key: 'remove',
              render: (text, { id }) => (
                <div
                  style={{
                    cursor: 'pointer',
                  }}
                  href="#"
                  onClick={() =>
                    dispatch(
                      removeStoreManagerRequest({ storeManagerRequestId: id }),
                    )
                  }
                >
                  Remove {id} from TO
                </div>
              ),
              fixed: 'right',
            },
          ]
          return (
            <Table
              rowKey={'id'}
              scroll={{ x: 1300 }}
              columns={columns}
              dataSource={record.storeManagerRequests}
              pagination={false}
            />
          )
        },
      }}
      rowClassName={({ isRequest }, index) =>
        isRequest ? 'is-request-row' : ''
      }
      footer={tableData => {
        const {
          total,
          shoes,
          accessories,
          shoesRequest,
          accessoriesRequest,
          totalRequest,
        } = tableData.reduce(
          (acc, { quantity, productType: { id } = {}, requests }) => {
            acc.total += quantity
            if (requests > 0) acc.totalRequest += requests
            if (id === '1') {
              acc.shoes += quantity
              acc.shoesRequest += requests
            } else {
              acc.accessories += quantity
              acc.accessoriesRequest += requests
            }

            return acc
          },
          {
            total: 0,
            shoes: 0,
            accessories: 0,
            shoesRequest: 0,
            accessoriesRequest: 0,
            totalRequest: 0,
          },
        )

        return `Shoes: ${shoes} - Accessories: ${accessories} - Total: ${total} | Shoes requested: ${shoesRequest} - Accessories requested: ${accessoriesRequest} - Total requested: ${totalRequest}`
      }}
    />
  )
}

export default RecapTable
