import { createStore, compose } from 'redux'

import reducer from './reducer'

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const configStore = (initialState = {}) => {
  return createStore(reducer, initialState, composeEnhancers())
}

export default configStore
