import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Table, Button, Popconfirm, message } from 'antd'
import { useAuth0 } from '../../auth0-wrapper'
import { useStoreManagerRequests } from '../../components/StoreManagerRequestProvider'
import { useStores } from '../../components/StoresProvider'
import { useCallback } from 'react'

const deleteStoreManagerRequest = (token, id) =>
  axios.delete(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/store-manager-request/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

const denyStoreManagerRequest = (token, id) =>
  axios.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/store-manager-request/deny/${id}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

const restoreStoreManagerRequest = (token, id) =>
  axios.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/store-manager-request/restore/${id}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

const Requests = () => {
  const [requests, setRequests] = useState([])
  const [pagination, setPagination] = useState(1)
  const [loading, setLoading] = useState(true)
  const { getTokenSilently, user } = useAuth0()

  const { getStoreManagerRequests, getTotal } = useStoreManagerRequests()
  const { getStoreName, stores } = useStores()

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true)
      getStoreManagerRequests({
        limit: 10,
        ...params,
      }).then(requests => {
        setLoading(false)
        setRequests(requests)
      })
    },
    [getStoreManagerRequests],
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  useEffect(() => {
    getTotal().then(data => {
      setPagination(p => ({ ...p, total: data.total }))
    })
  }, [getTotal])

  const handleTableChange = useCallback(
    (p, filters, sorter) => {
      setPagination({ ...pagination, current: p.current })
      fetch({
        results: pagination.pageSize,
        page: p.current,
        sortBy: sorter.field,
        order: sorter.order,
        ...filters,
      })

      getTotal({ ...filters }).then(data => {
        setPagination(p => ({ ...p, total: data.total }))
      })
    },
    [fetch, getTotal, pagination],
  )

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      fixed: 'left',
    },
    {
      title: 'Store ID',
      dataIndex: 'storeId',
      fixed: 'left',
    },
    {
      title: 'Store',
      dataIndex: 'storeId',
      render: id => getStoreName(id),
      filters: stores.map(({ id }) => ({
        value: id,
        text: getStoreName(id),
      })),
      fixed: 'left',
    },
    {
      title: 'Product ID',
      dataIndex: 'productId',
    },
    {
      title: 'SKU',
      dataIndex: 'SKU',
    },
    {
      title: 'Customer First Name',
      dataIndex: 'customerFirstName',
    },
    {
      title: 'Customer Last Name',
      dataIndex: 'customerLastName',
    },
    {
      title: 'Customer email',
      dataIndex: 'customerEmail',
    },
    {
      title: 'Notes',
      dataIndex: 'comments',
      width: 400,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      render: text => new Date(text).toLocaleString(),
    },
    {
      title: 'Updated on',
      dataIndex: 'updatedAt',
      render: text => new Date(text).toLocaleString(),
    },
    {
      title: 'Fulfilled?',
      dataIndex: 'fulfilled',
      render: text => (text ? 'Yes' : 'No'),
      filters: [
        {
          text: 'Yes',
          value: 1,
        },
        {
          text: 'No',
          value: 0,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Deleted?',
      dataIndex: 'deleted',
      render: text => (text ? 'Yes' : 'No'),
      filters: [
        {
          text: 'Yes',
          value: 1,
        },
        {
          text: 'No',
          value: 0,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Denied?',
      dataIndex: 'denied',
      render: text => (text ? 'Yes' : 'No'),
      filters: [
        {
          text: 'Yes',
          value: 1,
        },
        {
          text: 'No',
          value: 0,
        },
      ],
      filterMultiple: false,
    },
    ...(user.permissions.indexOf('write:transferorders') === -1
      ? [
          {
            title: 'Delete',
            key: 'delete',
            fixed: 'right',
            render: (text, record) => (
              <Popconfirm
                title="Are you sure to delete this request?"
                onConfirm={() =>
                  getTokenSilently().then(token =>
                    deleteStoreManagerRequest(token, record.id).then(() => {
                      message.success(`Request deleted`)

                      const index = requests.findIndex(r => r.id === record.id)

                      setRequests([
                        ...requests.slice(0, index),
                        {
                          ...requests[index],
                          deleted: 1,
                        },
                        ...requests.slice(index + 1),
                      ])
                    }),
                  )
                }
                okText="Yes"
                cancelText="No"
                disabled={record.fulfilled || record.deleted}
              >
                <Button disabled={record.fulfilled || record.deleted}>
                  Delete{record.deleted ? 'd' : ''}
                </Button>
              </Popconfirm>
            ),
          },
        ]
      : [
          {
            title: 'Deny',
            key: 'deny',
            fixed: 'right',
            render: (text, record) => (
              <Popconfirm
                title={
                  record.denied === 1
                    ? 'Are you sure you want to restore this request?'
                    : 'Are you sure you want to deny this request?'
                }
                onConfirm={() =>
                  getTokenSilently().then(token => {
                    if (record.denied) {
                      restoreStoreManagerRequest(token, record.id).then(() => {
                        const idx = requests.findIndex(r => r.id === record.id)
                        setRequests([
                          ...requests.slice(0, idx),
                          { ...record, denied: 0 },
                          ...requests.slice(idx + 1),
                        ])
                      })
                    } else {
                      denyStoreManagerRequest(token, record.id).then(() => {
                        const idx = requests.findIndex(r => r.id === record.id)
                        setRequests([
                          ...requests.slice(0, idx),
                          { ...record, denied: 1 },
                          ...requests.slice(idx + 1),
                        ])
                      })
                    }
                  })
                }
                okText="Yes"
                cancelText="No"
                disabled={record.fulfilled || record.deleted}
              >
                <Button disabled={record.fulfilled || record.deleted}>
                  {record.denied === 1 ? 'Restore' : 'Deny'}
                </Button>
              </Popconfirm>
            ),
          },
        ]),
  ]

  return (
    <Table
      scroll={{ x: 'max-content' }}
      bordered
      dataSource={requests}
      loading={loading}
      columns={columns}
      pagination={pagination}
      title={() => 'Store manager requests'}
      rowKey="id"
      onChange={handleTableChange}
    />
  )
}

export default Requests
