import React from 'react'
import { useDispatch } from 'react-redux'

import { Row, Col, Button } from 'antd'
import styled from 'styled-components/macro'

import ProductModelSelect from '../ProductModelSelect'
import StoreSelect from '../StoreSelect'
import WarehouseRadio from '../WarehouseRadio'

import { toggleSidebar } from '../../redux/sidebar/actions'
import BrandsRadio from '../BrandsRadio'
import SublocationSelect from '../SublocationSelect'

const Toolbar = props => {
  const dispatch = useDispatch()

  return (
    <>
      <Row type="flex" justify="space-around">
        <StyledColumn>
          <StoreSelect />
        </StyledColumn>
        <StyledColumn>
          <WarehouseRadio />
        </StyledColumn>
        <StyledColumn>
          <BrandsRadio />
        </StyledColumn>
        <StyledColumn>
          <ProductModelSelect />
        </StyledColumn>
        <StyledColumn>
          <Button type="primary" onClick={() => dispatch(toggleSidebar())}>
            Summary
          </Button>
        </StyledColumn>
      </Row>
      <SublocationSelect />
    </>
  )
}

const StyledColumn = styled(Col)`
  margin: 1rem 0;
`

export default Toolbar
