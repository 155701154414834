import React from 'react'

import * as S from './styles'

import WarehouseCell from '../WarehouseCell'

const WarehouseRow = ({ warehouseId, locationName, sizes, columns }) => {
  return (
    <>
      <S.IDCell>{warehouseId}</S.IDCell>
      <S.BoldCell>{locationName}</S.BoldCell>
      {columns.map(({ name, id, internalId }) => {
        const cell = sizes.filter(
          ({ value, SKU }) => value === name || SKU === name,
        )[0]
        if (!cell) {
          return (
            <WarehouseCell
              key={id}
              warehouseId={warehouseId}
              SKU={name}
              internalId={internalId}
              value={name}
              quantity={0}
              quantityOnOrder={0}
            />
          )
        } else {
          const {
            id,
            SKU,
            internalId: cellId,
            quantityAvailable,
            quantityOnOrder,
            value,
          } = cell
          return (
            <WarehouseCell
              key={id || SKU}
              warehouseId={warehouseId}
              SKU={SKU}
              internalId={cellId}
              value={value}
              quantity={quantityAvailable}
              quantityOnOrder={quantityOnOrder}
            />
          )
        }
      })}
      <div
        style={{
          textAlign: 'center',
          borderLeft: '1px solid #eee',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: '4px 0' }}>
          {sizes.reduce(
            (acc, { quantityAvailable }) => quantityAvailable + acc,
            0,
          )}
        </div>
      </div>
    </>
  )
}

export default WarehouseRow
