import { TOGGLE_SIDEBAR, OPEN_SIDEBAR, CLOSE_SIDEBAR } from './types'

export const openSidebar = () => ({
  type: OPEN_SIDEBAR,
})

export const closeSidebar = () => ({
  type: CLOSE_SIDEBAR,
})

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
})
