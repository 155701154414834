import React, { useMemo } from 'react'

import { Spin, Row, Col, Empty, Collapse } from 'antd'

import StoreRow from '../../components/StoreRow'
import WarehouseSection from '../../components/WarehouseSection'
import Toolbar from '../../components/Toolbar'
import ShoeCard from '../../components/ShoeCard'

import { useSelector } from 'react-redux'
import { getFilterValueByKey } from '../../redux/filters/reducer'

import * as S from './styles'
import CustomerCareToolbar from '../../components/CustomerCareToolbar'

import { useAuth0 } from '../../auth0-wrapper'
import { useProducts } from '../../components/ProductsProvider'
import { useStores } from '../../components/StoresProvider'
import StoreTotalRow from '../../components/StoreTotalRow'

import groupBy from 'lodash.groupby'
import mapValues from 'lodash.mapvalues'

const sizeOrder = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']

const sortSizes = (sizeA, sizeB) => {
  if (parseInt(sizeA)) return sizeA - sizeB
  return sizeOrder.indexOf(sizeA) - sizeOrder.indexOf(sizeB)
}

const Section = props => {
  const { user } = useAuth0()
  const { products, loading, fetch } = useProducts()

  const productbyProductionStatusAndSeason = useMemo(() => {
    return mapValues(groupBy(products, 'productionStatus'), p =>
      groupBy(p, 'season.value'),
    )
  }, [products])

  return (
    <>
      <Row
        style={{
          position: 'sticky',
          top: '64px',
          zIndex: '1',
          background: '#f0f2f5',
        }}
      >
        <Col style={{ flexGrow: 1 }}>
          {user.permissions.indexOf('write:transferorders') > -1 ? (
            <Toolbar />
          ) : (
            <CustomerCareToolbar onSearch={fetch} disabled={loading} />
          )}
        </Col>
      </Row>

      {loading ? (
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          style={{ flexGrow: 1 }}
        >
          <Col>
            <S.LoaderContainer>
              <Spin />
            </S.LoaderContainer>
          </Col>
        </Row>
      ) : (
        <>
          {!productbyProductionStatusAndSeason ||
          Object.entries(productbyProductionStatusAndSeason).length === 0 ? (
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              style={{ flexGrow: 1 }}
            >
              <Col>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={24}>
                <Collapse defaultActiveKey={'Still produced'}>
                  {Object.keys(productbyProductionStatusAndSeason).map(
                    productionStatus => {
                      const seasonalProducts =
                        productbyProductionStatusAndSeason[productionStatus]
                      return (
                        <Collapse.Panel
                          header={productionStatus}
                          key={productionStatus}
                          className="production-collapse"
                        >
                          <Collapse
                            defaultActiveKey={Object.keys(seasonalProducts)}
                          >
                            {Object.keys(seasonalProducts).map(season => (
                              <Collapse.Panel header={season} key={season}>
                                <MemoListOfSeasonalProducts
                                  products={seasonalProducts[season]}
                                />
                              </Collapse.Panel>
                            ))}
                          </Collapse>
                        </Collapse.Panel>
                      )
                    },
                  )}
                </Collapse>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  )
}
const ListOfSeasonalProducts = ({ products, ...props }) => {
  const { getStoreSublocations, getStoreName } = useStores()
  const selectedStores = useSelector(getFilterValueByKey('store'))
  const selectedSublocations =
    useSelector(getFilterValueByKey('sublocations')) || []
  const showSublocations = useSelector(getFilterValueByKey('showSublocations'))
  const warehouseType = useSelector(getFilterValueByKey('warehouseType'))

  return Object.entries(products)
    .sort((a, b) => {
      const {
        name: nameA,
        parent: { value: SKUA },
      } = a[1]
      const firstWordA = nameA.split(' ')[0]
      const {
        name: nameB,
        parent: { value: SKUB },
      } = b[1]
      const firstWordB = nameB.split(' ')[0]

      return `${firstWordA}${SKUA}`.localeCompare(`${firstWordB}${SKUB}`)
    })
    .map(
      (
        [
          key,
          {
            name,
            inventoryLocations,
            parent: { value },
            imageUrl,
            soleType,
          },
        ],
        index,
      ) => {
        const colSizes = Object.values(inventoryLocations)[0]
          .sizes.sort((a, b) => sortSizes(a.value || a.SKU, b.value || b.SKU))
          .map(({ id, value, SKU, internalId }) => ({
            name: value || SKU,
            id: id || SKU,
            internalId,
            SKU,
          }))
        const columns = [
          {
            name: 'ID',
            id: 'id',
          },
          {
            name: '',
            id: 0,
          },
          ...colSizes,
          {
            name: 'Total',
            id: 'total',
            fixed: 'right',
          },
        ]

        let warehouses

        // selectedStores è sempre un array, anche quando c'è un solo elemento
        if (selectedStores?.length > 0) {
          // 2: FIEGE 00
          // 3: FIEGE 03
          // 4: FIEGE 04
          if (warehouseType === 'fiege') {
            warehouses = ['2', '3', '4'].map(w => inventoryLocations[w])
          }

          // 174: FLI 00
          // 176: FLI 02
          // 177: FLI 03
          if (warehouseType === 'fli') {
            warehouses = ['174', '176', '177'].map(w => inventoryLocations[w])
          }
        }

        return (
          <Row
            gutter={8}
            key={key}
            style={{ marginBottom: '1rem' }}
            type="flex"
          >
            <Col xs={0} xl={4}>
              <ShoeCard image={imageUrl} name={name} value={value} />
            </Col>
            <Col lg={24} xl={20}>
              <S.Card
                title={`${name}${soleType && ` - ${soleType}`}`}
                extra={value}
              >
                <S.Table size={columns.length}>
                  {columns.map(({ name, id }) => {
                    return <S.HeaderCell key={id}>{name}</S.HeaderCell>
                  })}
                  <WarehouseSection
                    warehouses={warehouses}
                    columns={colSizes}
                  />
                  {selectedStores &&
                    selectedStores.length > 0 &&
                    selectedStores.map(selectedStore => {
                      // Per ogni store selezionato prendo le sue sublocations
                      const sublocations = getStoreSublocations(selectedStore)
                        .filter(s => selectedSublocations.includes(s.id))
                        .map(({ id }) => id)

                      const storeRows = [
                        ...[selectedStore, ...sublocations]
                          .map(s => inventoryLocations[s])
                          .filter(s => !!s),
                      ]

                      const total = colSizes.map(
                        ({ name, id, internalId, SKU }) => {
                          const storeQuantities = storeRows.reduce(
                            (acc, { sizes }) => {
                              const size = sizes.find(
                                ({ internalId: sizeIntId }) =>
                                  internalId === sizeIntId,
                              )
                              // non sempre c'è la taglia, per cui controllo
                              // la sua esistenza e se non c'è passo al
                              // al successivo
                              if (!size) {
                                return acc
                              }
                              const { quantityAvailable, quantityOnOrder } =
                                size
                              return {
                                quantityAvailable:
                                  acc.quantityAvailable + quantityAvailable,
                                quantityOnOrder:
                                  acc.quantityOnOrder + quantityOnOrder,
                              }
                            },
                            { quantityAvailable: 0, quantityOnOrder: 0 },
                          )

                          return {
                            value: name,
                            id,
                            internalId,
                            SKU,
                            quantityAvailable:
                              storeQuantities.quantityAvailable,
                            quantityOnOrder: storeQuantities.quantityOnOrder,
                          }
                        },
                      )

                      return (
                        <React.Fragment key={selectedStore}>
                          {showSublocations &&
                            [selectedStore, ...sublocations].map(
                              (storeID, index) => {
                                const store = inventoryLocations[storeID]
                                return (
                                  <StoreRow
                                    key={
                                      store?.inventoryLocation?.id || storeID
                                    }
                                    first={index === 0}
                                    sizes={
                                      store?.sizes?.sort((a, b) =>
                                        sortSizes(
                                          a.value || a.SKU,
                                          b.value || b.SKU,
                                        ),
                                      ) || []
                                    }
                                    locationName={
                                      store?.inventoryLocation?.value ||
                                      getStoreName(storeID)
                                    }
                                    storeId={
                                      store?.inventoryLocation?.id || storeID
                                    }
                                    columns={colSizes}
                                  />
                                )
                              },
                            )}
                          {(sublocations.length > 0 ||
                            (sublocations.length === 0 &&
                              showSublocations === false)) && (
                            <StoreTotalRow
                              rowId={showSublocations ? 'Tot' : selectedStore}
                              sizes={total}
                              locationName={getStoreName(selectedStore)}
                              storeIds={[selectedStore, ...sublocations]}
                              columns={colSizes}
                            />
                          )}
                        </React.Fragment>
                      )
                    })}
                </S.Table>
              </S.Card>
            </Col>
          </Row>
        )
      },
    )
}

const MemoListOfSeasonalProducts = React.memo(ListOfSeasonalProducts)

export default Section
