import { combineReducers } from 'redux'

import quantities from './quantities/reducer'
import filters from './filters/reducer'
import sidebar from './sidebar/reducer'

export default combineReducers({
  sidebar,
  filters,
  quantities,
})
