import React, { useState, useImperativeHandle } from 'react'

import { Form, Modal, Input, message } from 'antd'

const EmailForm = React.forwardRef(
  (
    {
      visible,
      onCancel,
      onSubmit,
      defaultEmailValue: { subject, body, cc, to },
    },
    ref,
  ) => {
    const [loading, setIsLoading] = useState(false)
    const [form] = Form.useForm()

    useImperativeHandle(ref, () => ({
      form,
    }))

    return (
      <Modal
        width={1000}
        visible={visible}
        title={`Send email to FIEGE`}
        okText="Send email"
        onCancel={onCancel}
        onOk={e => {
          setIsLoading(true)
          if (typeof onSubmit === 'function') {
            const result = onSubmit()

            if (result instanceof Promise) {
              result
                .then(() => setIsLoading(false))
                .catch(err => {
                  err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message &&
                    message.error(err.response.data.message)
                  setIsLoading(false)
                })
            } else {
              setIsLoading(false)
            }
          }
        }}
        confirmLoading={loading}
        centered
      >
        <Form
          form={form}
          layout="horizontal"
          labelCol={{
            xs: { span: 24 },
            sm: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
          }}
          initialValues={{
            cc,
            emailSubject: subject,
            emailBody: body,
          }}
        >
          <Form.Item label="From">
            <Input type="email" value="alessandro.villa@velasca.com" disabled />
          </Form.Item>
          <Form.Item label="To">
            <Input type="text" value={to} />
          </Form.Item>
          <Form.Item label="CC" name="cc">
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Subject" name="emailSubject">
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Email message" name="emailBody">
            <Input.TextArea rows={17} />
          </Form.Item>
        </Form>
      </Modal>
    )
  },
)

export default EmailForm
