import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Input } from 'antd'

import { setFilter } from '../../redux/filters/actions'
import { getFilterValueByKey } from '../../redux/filters/reducer'

const ProductParentSelect = ({ disabled, onPressEnter }) => {
  const dispatch = useDispatch()

  const value = useSelector(getFilterValueByKey('productParent'))

  return (
    <Input
      allowClear
      style={{ width: 300 }}
      value={value}
      placeholder="Search by parent"
      disabled={disabled}
      onPressEnter={onPressEnter}
      onChange={e => {
        dispatch(
          setFilter({
            key: 'productParent',
            value: e.target.value,
          }),
        )
      }}
    />
  )
}

export default ProductParentSelect
