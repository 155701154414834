import React from 'react'
import styled from 'styled-components/macro'
import ImageZoom from 'react-medium-image-zoom'

import { Card } from 'antd'

const ImageNoZoom = props => {
  return <img src={props.image.src} alt={props.image.alt} />
}

const ShoeCard = ({ image, name, value, zoom = true, style }) => {
  const ImageComponent = zoom === false ? ImageNoZoom : ImageZoom

  return (
    <StyledCard
      style={style}
      cover={
        <ImageComponent
          image={{
            src: image,
            alt: name,
          }}
          zoomImage={{
            src: image,
            alt: name,
          }}
        />
      }
    >
      {value}
    </StyledCard>
  )
}

export default ShoeCard

export const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
`
