import { SET_FILTER, REMOVE_FILTER } from './types'
import { createSelector } from 'reselect'

const filtersReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    case REMOVE_FILTER:
      const { [action.payload.key]: filter, ...restState } = state
      return [...restState]
    default:
      return state
  }
}

export default filtersReducer

export const getFilters = state => state.filters

export const getFilterValueByKey = key =>
  createSelector(getFilters, filters => filters[key])
