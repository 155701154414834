import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/browser'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { Auth0Provider } from './auth0-wrapper'
import config from './auth_config.json'

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

Sentry.init({
  dsn: 'https://3a2d9660057b44e9a43ddc67911140bb@sentry.io/1549236',
  environment: process.env.REACT_APP_ENVIRONMENT,
})

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    audience={config.audience}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
