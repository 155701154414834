import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterValueByKey } from '../../redux/filters/reducer'
import { useStores } from '../StoresProvider'
import { useProducts } from '../ProductsProvider'
import { setFilter } from '../../redux/filters/actions'

import { Checkbox, Row, Col } from 'antd'
import styled from 'styled-components'

const SublocationSelect = () => {
  const dispatch = useDispatch()
  const { getStoreSublocations, getStoreName } = useStores()
  const { loading } = useProducts()
  const selectedStore = useSelector(getFilterValueByKey('store'))[0]
  const sublocations = useMemo(
    () => getStoreSublocations(selectedStore) || [],
    [getStoreSublocations, selectedStore],
  )

  if (sublocations.length === 0) {
    return null
  }

  return (
    <StyledCheckboxGroup
      disabled={loading}
      name="sublocations"
      defaultValue={sublocations.map(({ id }) => id)}
      onChange={values => {
        dispatch(setFilter({ key: 'sublocations', value: values }))
      }}
    >
      <StyledRow type="flex" justify="center">
        {sublocations.map(({ id }) => (
          <StyledColumn key={id}>
            <Checkbox value={id}>{getStoreName(id)}</Checkbox>
          </StyledColumn>
        ))}
      </StyledRow>
    </StyledCheckboxGroup>
  )
}

const StyledCheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
`
const StyledColumn = styled(Col)`
  margin: 0.5rem 1rem 1.5rem;
`
const StyledRow = styled(Row)`
  width: 100%;
`

export default SublocationSelect
