import React from 'react'

import { Radio } from 'antd'

import { useDispatch, useSelector } from 'react-redux'

import { getFilterValueByKey } from '../../redux/filters/reducer'
import { setFilter } from '../../redux/filters/actions'

const BrandsRadio = ({ disabled, defaultValue = 'men' }) => {
  const dispatch = useDispatch()
  const brand = useSelector(getFilterValueByKey('brands'))
  return (
    <Radio.Group
      value={brand}
      buttonStyle="solid"
      disabled={disabled}
      onChange={e => {
        dispatch(setFilter({ key: 'brands', value: e.target.value }))
      }}
    >
      <Radio.Button value="all">All</Radio.Button>
      <Radio.Button value="men">Men</Radio.Button>
      <Radio.Button value="women">Women</Radio.Button>
    </Radio.Group>
  )
}

export default BrandsRadio
