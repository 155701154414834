import React from 'react'

import orderBy from 'lodash.orderby'

import { useDispatch, useSelector } from 'react-redux'

import Select from '../Select'

import { useProductModels } from '../ProductModelProvider'
import { useProducts } from '../ProductsProvider'

import { setFilter } from '../../redux/filters/actions'

import { getFilterValueByKey } from '../../redux/filters/reducer'

const makeOption = ({ values: { custrecord_velprod_model_nameit }, id }) => ({
  key: id,
  value: id,
  label: custrecord_velprod_model_nameit,
})

const ProductModelSelect = props => {
  const { productModels } = useProductModels()
  const dispatch = useDispatch()
  const value = useSelector(getFilterValueByKey('productModel'))

  const { loading } = useProducts()

  return (
    <Select
      options={orderBy(productModels.map(makeOption), 'label')}
      placeholder="Select a model"
      disabled={loading}
      value={value}
      onChange={val => dispatch(setFilter({ key: 'productModel', value: val }))}
    />
  )
}

export default ProductModelSelect
