import styled from 'styled-components/macro'

export const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding-top: 10px;
  border-top: 1px solid #e8e8e8;
  cursor: ${props => (props.changed ? 'help' : 'default')};
  ${props => (props.requests > 0 ? 'background-color: #5F4B8B;' : '')}
  ${props => (props.changed ? 'background-color:  green;' : '')}
  ${props => props.changed && 'color:  white;'}
  ${props => props.requests && 'color:  white;'}
  ${props => props.alert && 'color: red;'}

  &:hover {
    background-color: #e8e8e8;
  }
`

export const CellValue = styled.div`
  padding: 0.25rem;
  font-weight: bold;
`

export const CellDiff = styled.span`
  color: #008000;
  font-weight: bold;
`
