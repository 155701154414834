import React from 'react'

import { Row, Col, Button, Switch } from 'antd'
import styled from 'styled-components/macro'

import { useSelector, useDispatch } from 'react-redux'

import { setFilter } from '../../redux/filters/actions'

import ProductModelSelect from '../ProductModelSelect'
import MultiStoresSelect from '../MultiStoresSelect'
import ModelCategoryRadio from '../ModelCategoryRadio'
import ProductNameSelect from '../ProductNameSelect'
import ProductParentSelect from '../ProductParentSelect'
import BrandsRadio from '../BrandsRadio'

import { getFilterValueByKey } from '../../redux/filters/reducer'
import { useProducts } from '../ProductsProvider'

const CustomerCareToolbar = ({ onSearch }) => {
  const searchType = useSelector(getFilterValueByKey('searchType'))
  const store = useSelector(getFilterValueByKey('store'))
  const dispatch = useDispatch()
  const { loading } = useProducts()

  const showSublocations = useSelector(getFilterValueByKey('showSublocations'))
  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '10px' }}>
        <StyledColumn>
          <MultiStoresSelect disabled={loading} />
        </StyledColumn>
      </Row>
      <Row justify="space-between" style={{ marginBottom: '10px' }}>
        <StyledColumn span={6}>
          Cerca per: <ModelCategoryRadio disabled={loading} />
        </StyledColumn>
        <StyledColumn span={9}>
          {searchType === 'productName' && (
            <>
              <ProductNameSelect disabled={loading} />
              <Switch
                defaultChecked={false}
                disabled={loading}
                onChange={e => {
                  dispatch(
                    setFilter({
                      key: 'showAll',
                      value: e,
                    }),
                  )
                }}
              />{' '}
              Mostra tutta la categoria
            </>
          )}
          {searchType === 'productModel' && (
            <ProductModelSelect disabled={loading} />
          )}
          {searchType === 'productParent' && (
            <ProductParentSelect disabled={loading} onPressEnter={onSearch} />
          )}
        </StyledColumn>
        <StyledColumn span={4}>
          {searchType !== 'productParent' && (
            <BrandsRadio defaultValue="all" disabled={loading} />
          )}
        </StyledColumn>
        <StyledColumn span={3}>
          <Switch
            defaultChecked={showSublocations}
            disabled={loading}
            onChange={e => {
              dispatch(
                setFilter({
                  key: 'showSublocations',
                  value: e,
                }),
              )
            }}
          />{' '}
          Mostra dettaglio
        </StyledColumn>
        <StyledColumn span={2}>
          <Button
            type="primary"
            onClick={onSearch}
            disabled={!store || store.length === 0 || loading}
          >
            Search
          </Button>
        </StyledColumn>
      </Row>
    </>
  )
}

const StyledColumn = styled(Col)``

export default CustomerCareToolbar
