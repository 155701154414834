import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import mergeDeep from 'deepmerge'

import { useSelector } from 'react-redux'
import axios from 'axios'

import pickBy from 'lodash.pickby'
import uniqBy from 'lodash.uniqby'

import { setupCache } from 'axios-cache-adapter'

import { getFilterValueByKey } from '../../redux/filters/reducer'

import { useStores } from '../StoresProvider'

import { transformProducts } from './utils'

import { useAuth0 } from '../../auth0-wrapper'

export const allOccurencesOnceMerge = (
  destinationArray,
  sourceArray,
  options,
) => {
  return [...sourceArray, ...destinationArray].reduce((acc, el) => {
    if (acc.indexOf(el) > -1) {
      return acc
    } else {
      acc.push(el)
    }

    return acc
  }, [])
}

export const customMerge = key => {
  if (key === 'children' || key === 'sizes') {
    return mergeSKUs
  }
}

export const mergeSKUs = (a, b) => {
  return [...a, ...b].reduce((acc, el) => {
    // check if already in array
    const index = acc.findIndex(({ SKU }) => {
      return SKU === el.SKU
    })

    if (index > -1) {
      acc = [
        ...acc.slice(0, index),
        {
          ...acc[index],
          ...el,
        },
        ...acc.slice(index + 1),
      ]
    } else {
      acc = [...acc, el]
    }

    return acc
  }, [])
}

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    query: false,
  },
})

const api = axios.create({
  adapter: cache.adapter,
})

const fetchItems = async ({
  store,
  productModel,
  page = 1,
  token,
  internalId,
  brands,
  productNames,
  productParent,
}) => {
  const result = await api
    .get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/products?${
        productModel ? `productModel=${productModel}&` : ''
      }storeId=${arrayToCommas(store)}&page=${page}${
        internalId ? `&internalId=${internalId}` : ''
      }${
        brands && brands.length > 0 ? `&brands=${arrayToCommas(brands)}` : ''
      }${
        productNames && productNames.length > 0
          ? `&productName=${arrayToCommas(productNames)}`
          : ''
      }${productParent ? `&parent=${productParent}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(({ data }) => data)

  if (result.length === 1000) {
    return [
      ...result,
      ...(await fetchItems({
        store,
        productModel,
        page: page + 1,
        token,
        internalId,
        brands,
        productNames,
      })),
    ]
  }

  return result
}

const arrayToCommas = arr => {
  return Array.isArray(arr)
    ? arr.reduce((acc, el, i) => {
        if (i === 0) return `${el}`
        acc += `,${el}`
        return acc
      }, '')
    : arr
}

export const ProductContext = React.createContext()
export const useProducts = () => useContext(ProductContext)

export const lookupFilterToIds = filterValue => {
  const mapping = {
    men: ['1', '2', '3', '6', '7', '8', '9', '11', '13'],
    all: ['1', '2', '3', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
    women: ['5', '10', '13'],
  }
  return mapping[filterValue]
}

export const ProductsProvider = ({ children, auto, allWarehouses }) => {
  const selectedStores = useSelector(getFilterValueByKey('store'))
  const productModel = useSelector(getFilterValueByKey('productModel'))
  const productName = useSelector(getFilterValueByKey('productName'))
  const productParent = useSelector(getFilterValueByKey('productParent'))
  const warehouseType = useSelector(getFilterValueByKey('warehouseType'))
  const showAll = useSelector(getFilterValueByKey('showAll'))
  const brands = lookupFilterToIds(useSelector(getFilterValueByKey('brands')))

  const searchType = useSelector(getFilterValueByKey('searchType'))

  const { stores, getStoreSublocations } = useStores()

  const warehouses = useMemo(() => {
    // I put a check on Negozio Tortona (173) because I need to see all the warehouses just for this store
    if (allWarehouses === false && !selectedStores.includes('173')) {
      return ['2', '3']
    }

    // 2: FIEGE 00
    // 3: FIEGE 03
    // 4: FIEGE 04
    if (warehouseType === 'fiege') {
      return ['2', '3', '4']
    }

    // 174: FLI 00
    // 176: FLI 02
    // 177: FLI 03
    if (warehouseType === 'fli') {
      return ['174', '176', '177']
    }

    // If i want to see in-store availabilities, I need to select stores ID as warehouses
    return stores.map(e => e.id)
  }, [allWarehouses, stores, warehouseType, selectedStores])

  const [products, setProducts] = useState({})
  const [warehouseData, setWarehouseData] = useState({})
  const [loading, setLoading] = useState(false)

  const { getTokenSilently } = useAuth0()

  const getProduct = useCallback(
    ({ SKU, internalId, parentId }) => {
      // Prima trovo il parent
      const found = Object.entries(products).find(([key, p]) => {
        return (
          parentId === key ||
          p.SKUs.indexOf(SKU) > -1 ||
          p.internalIDs.indexOf(internalId) > -1
        )
      })

      if (found) {
        const [, product] = found

        const child = product.children.find(
          c => c.internalId === internalId || c.SKU === SKU,
        )
        return {
          ...product,
          ...child,
        }
      }

      return {}
    },
    [products],
  )

  const getWarehouseName = useCallback(
    id => {
      const w = warehouseData.find(w => w.id === id)

      if (!w) return ''

      return w.value
    },
    [warehouseData],
  )

  const getProductAvailabilities = useCallback(
    async ({ productId, store }) => {
      const token = await getTokenSilently().catch(() => false)
      if (token) {
        const product = transformProducts(
          await fetchItems({
            store: ['2', '3', '4'],
            token,
            internalId: productId,
          }),
        )

        setProducts(p => {
          return mergeDeep(p, product, {
            arrayMerge: allOccurencesOnceMerge,
            customMerge,
          })
        })

        return product
      }
    },
    [getTokenSilently],
  )

  const executeFetch = useCallback(async () => {
    console.log('warehouses changed!', { warehouses })
    setLoading(true)
    const token = await getTokenSilently().catch(() => false)

    if (!token) {
      setLoading(false)
      return
    }
    const selectedStoreSublocations =
      selectedStores
        .reduce(
          (acc, selectedStore) => [
            ...acc,
            ...getStoreSublocations(selectedStore),
          ],
          [],
        )
        ?.map(({ id }) => id) || []

    const fetchedProducts = await fetchItems({
      store: [
        ...selectedStores,
        ...selectedStoreSublocations,
        ...(warehouses.length > 0
          ? warehouses.filter(w => !selectedStores.includes(w))
          : []),
      ],
      token,
      productModel: searchType === 'productModel' ? productModel : false, // no productModel if productParent
      brands: searchType === 'productParent' ? false : brands,
      productNames:
        showAll || !productName || searchType !== 'productName'
          ? false
          : [
              productName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
              productName,
            ],
      productParent: searchType === 'productParent' ? productParent : false,
    }).catch(e => {
      console.log(e)
      setLoading(false)

      return false
    })

    if (fetchedProducts === false) return

    setWarehouseData(
      uniqBy(fetchedProducts, e => e['inventoryLocation.internalid'].id)
        .filter(e =>
          ['2', '3', '4'].includes(e['inventoryLocation.internalid'].id),
        )
        .map(e => e['inventorylocation']),
    )

    setProducts(p => {
      return mergeDeep(p, transformProducts(fetchedProducts), {
        arrayMerge: allOccurencesOnceMerge,
        customMerge,
      })
    })

    setLoading(false)
  }, [
    getTokenSilently,
    getStoreSublocations,
    selectedStores,
    warehouses,
    searchType,
    productModel,
    brands,
    showAll,
    productName,
    productParent,
  ])

  useEffect(() => {
    if (selectedStores && productModel && auto) {
      executeFetch()
    }
    // rerun fetch when store changes, model changes, auto is enabled or warehouses changes (from FIEGE to FLI)
    //eslint-disable-next-line
  }, [selectedStores, productModel, auto, warehouses])

  return (
    <ProductContext.Provider
      value={{
        products: pickBy(products, p => {
          if (searchType === 'productParent') {
            return p.parent.value === productParent
          }

          if (p.productModelId !== productModel) {
            return false
          }

          if (!brands.includes(p.brand)) {
            return false
          }

          if (productName) {
            if (
              p.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .indexOf(
                  productName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                ) > -1
            ) {
              return true
            } else {
              return false
            }
          }

          return true
        }),
        loading,
        fetch: executeFetch,
        getProduct,
        getProductAvailabilities,
        getWarehouseName,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
