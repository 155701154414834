import styled from 'styled-components/macro'

export const BoldCell = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
`

export const IDCell = styled(BoldCell)`
  justify-content: center;
`
